
































import { defineComponent, getCurrentInstance, ref } from '@vue/composition-api'

import InputMultipleFile from '@/components/atoms/InputMultipleFile.vue'

export default defineComponent({
  components: {
    InputMultipleFile,
  },
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const files = ref([])

    // called by parent component
    const show = () => {
      // @ts-ignore
      instance.proxy.$refs.modal.show()
      setTimeout(() => {
        files.value = []
      })
    }

    const handleOk = (ok: () => void) => {
      context.emit('import-file-upload', files.value)
      ok()
    }

    return {
      files,
      show,
      handleOk,
    }
  },
})
