













import { defineComponent, ref, PropType } from '@vue/composition-api'

/**
 * ファイル選択部品
 *
 * 単一のファイルを選択
 */
export default defineComponent({
  props: {
    /**
     * File 情報。v-model での利用を想定
     */
    value: {
      type: Array as PropType<File[]>,
    },
    /**
     * You can mix and match IANA media types and extensions.
     * accept="image/*", (Accept all image formats by IANA media type wildcard)
     * accept="image/jpeg, image/png, image/gif"
     * accept=".jpg, .png, .gif"
     */
    accept: {
      type: String as PropType<string>,
      default: 'text/csv',
    },
  },
  setup(props, context) {
    const files = ref(props.value)
    return {
      files,
      context,
    }
  },
})
