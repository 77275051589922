import { ApolloError, NetworkStatus } from 'apollo-client'

import { ColumnLabel } from '@/types/entities'
import { Where, WhereOp, OrderBy } from '@/types/generated/graphql'
import {
  AsyncStatusEncodingType,
  AsyncStatusFormatType,
  TemplateFile,
  ResourceType,
} from '@/types/generated/graphql'
import apiClient from '@/utils/apiClient'
import Toast, { genNotifyMessage } from '@/utils/toast'
import i18n from '@/vue-i18n'

export default class {
  constructor(vue: any) {
    // @ts-ignore
    this.vue = vue
  }
  /*********************************
   * 以下はインポートリクエスト用の処理
   *********************************/
  /**
   * インポート開始処理
   */
  async import(params: {
    files: File[]
    resourceType: ResourceType
    optionXlsx?: { skipRows: number }
  }) {
    const { files, resourceType } = params
    if (files.length === 0) {
      console.log('no file detected.')
      return
    }
    const operationIdList = []
    try {
      for (const file of files) {
        //
        // S3へファイルアップロードを行うための一時認証情報付きURL(PresignedURL)を取得する
        //
        const {
          data: {
            createUploadUrl: { urlPutFile, putFilepath },
          },
        } = await apiClient.mutationCreateUploadUrl({
          filename: file.name,
        })
        //
        // ファイルアップロードをS3へ行う
        //
        const uploadResult = await fetch(urlPutFile, {
          method: 'PUT',
          body: await file.arrayBuffer(),
        })
        if (!uploadResult.ok) {
          throw new Error(
            'failed to upload put file to S3 bucket. url: ' +
              urlPutFile +
              ', status: ' +
              uploadResult.status +
              '.'
          )
        }
        //
        // アップロード完了報告をバックエンドサーバーへ行う
        //
        const {
          data: {
            runSimpleImport: { operationId },
          },
        } = await apiClient.mutationRunSimpleImport({
          formatType: AsyncStatusFormatType.Xlsx,
          encodingType: AsyncStatusEncodingType.Inherit,
          filepathImport: putFilepath,
          resourceType: resourceType,
          optionXlsx: params.optionXlsx,
        })
        operationIdList.push(operationId)
      }

      // インポート完了処理をユーザーへ行う
      this.completeImport({ idList: operationIdList })
    } catch (e) {
      this._handleImportExportError('import', e)
    }
  }
  /**
   * インポート完了処理
   */
  completeImport(params: { idList: string[] }) {
    // @ts-ignore
    this.vue.$bvModal.msgBoxOk([this._createAfterMessage('import', params)], {
      // @ts-ignore
      title: this.vue.$i18n.t('modal.import'),
      okTitle: '閉じる',
      okVaritna: 'outline-dark',
      centered: true,
      hideHeaderClose: false,
      id: 'export-import-modal',
    })
  }
  /*********************************
   * 以下はエクスポートリクエスト用の処理
   *********************************/
  /**
   * エクスポート処理
   */
  async export(params: {
    query: string
    jmespathQuery: string
    templateFile: TemplateFile
    filenameExport?: string
    columnLabelMapping?: ColumnLabel[]
    optionXlsx?: { header: boolean }
  }) {
    try {
      const {
        data: {
          bulkOperationRunQuery: { operationId },
        },
      } = await apiClient.bulkOperationRunQuery({
        query: params.query,
        jmespathQuery: params.jmespathQuery,
        formatType: AsyncStatusFormatType.Xlsx,
        encodingType: AsyncStatusEncodingType.Inherit,
        templateFile: params.templateFile,
        filenameExport: params.filenameExport,
        // 入れ子になるカラムがある場合にはcolumnLabelMappingを使用して列の並び替えを表現することが必要。toは空白で良い。入れ子でないものには不要
        columnLabelMapping: params.columnLabelMapping,
        optionXlsx: params.optionXlsx,
      })
      await this.completeExport({ idList: [operationId] })
    } catch (e) {
      this._handleImportExportError('export', e)
    }
  }
  /**
   * エクスポート完了処理
   */
  completeExport(params: { idList: string[] }) {
    console.log('completeExport')
    // @ts-ignore
    this.vue.$bvModal.msgBoxOk(
      // @ts-ignore
      [this._createAfterMessage('export', params)],
      {
        // @ts-ignore
        title: this.vue.$i18n.t('modal.export'),
        okTitle: '閉じる',
        centered: true,
        hideHeaderClose: false,
        id: 'export-import-modal',
      }
    )
  }
  _createAfterMessage(type: string, params: { idList: string[] }) {
    // @ts-ignore
    const h = this.vue.$createElement
    const elementOperationIdList = params.idList.map((id) => {
      return h('div', [
        // @ts-ignore
        this.vue.$i18n.t('label.operationId'),
        ':',
        h('span', id),
      ])
    })
    const messageVNode = h('div', { class: ['foobar'] }, [
      '受け付けました',
      ...elementOperationIdList,
      h('div', { class: ['mb-4'] }),
      h(
        'div',
        {
          // @ts-ignore
          on: { click: () => this.vue._toHistoryPage(type) },
          class: ['history-link'],
        },
        '抽出状況は入出力履歴一覧をご確認ください。'
      ),
    ])
    return messageVNode
  }
  _handleImportExportError(context: 'import' | 'export', e: unknown) {
    console.log('_handleImportExportError', context, e)
    // genNotifyMessage の中にある createApolloErrorMessage に渡す e includeGraphQLError を期待していて
    // includeGraphQLError は graphQLErrors と networkError という要素を保持している
    const isApporoError = ['graphQLErrors', 'networkError'].every((prop) =>
      Object.prototype.hasOwnProperty.call(e, prop)
    )

    if (isApporoError) {
      // @ts-ignore
      Toast.error(this.vue, {
        message: genNotifyMessage('e.regist', context, e as ApolloError),
      })
    } else {
      // @ts-ignore
      const message = this.vue.$i18n.t(
        'notification.e.unexpected',
        []
      ) as string
      // @ts-ignore
      Toast.error(this.vue, { message })
    }
  }
  /**
   * クエリを生成するための関数
   */
  // クエリを生成するための関数
  generateQueryString(params: {
    rowQuery: string
    resourceType: ResourceType
    where: Where[]
  }) {
    console.log({ params })
    // 改行コード削除
    return `# ${params.resourceType}\n# ${JSON.stringify(
      params.where
    )}\n${params.rowQuery.replace(/\r?\n/g, '')}`
  }
  // where句部分を生成するための関数
  generateQueryWhereString(wheres: Where[]) {
    // console.log({ wheres })
    // {field: "expireDate" op: BETWEEN value: ["2023-08-15" "2023-08-16"] }
    let whereString = '[\n'
    for (const where of wheres) {
      // console.log({ where })
      let valueString = '['
      if (where.value) {
        for (const value of where.value) {
          valueString += '"' + value + '" '
        }
      }
      valueString += ']'
      whereString += `{field: "${where.field}" op: ${where.op} value: ${valueString} }\n`
    }
    whereString += ']'
    // console.log({ whereString })
    return whereString
  }
  // orderBy部分を生成するための関数
  generateQueryOrderByString(orderBy: OrderBy[]) {
    let orderByString = '[\n'
    for (const ele of orderBy) {
      orderByString += `{field: "${ele.field}" direction: ${ele.direction} nullsOption: ${ele.nullsOption} }\n`
    }
    orderByString += ']'
    // console.log({ orderByString })
    return orderByString
  }
}
